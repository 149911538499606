@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
}

html {
  line-height: 1.5;
  color: #07081d;
  display: flex;
}

/* h1 {
    font-size: 2rem;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
}

h2 {
    font-size: 1rem;
    text-transform: lowercase;
    font-style: normal;
    font-weight: 700;
    margin: 0 0 0.5rem;
} */

/* h3 {
    font-size: 1rem;
    font-family: 'space mono';
    margin: 0;
} */

/* p {
    font-family: 'poppins';
} */

/* .bigP {
    font-size: 1.5rem;
} */

/* a {
    text-decoration: none;
    color: black;
    font-family: 'space mono';
} */

/* figure {
    margin: 0;
} */

/* button {
    border: none;
    background-color: transparent;
    padding: 0.5rem 1rem;
}
button:hover {
    cursor: pointer;
    font-style: italic;
} */
/* button:hover {
    background-color: #ff7edd;
    font-style: italic;
    font-weight: 700;
} */
/* 
.twoColumns {
    width: 24.94047619vw;
    width: 40%;
    7 columns with 6rem margin and 0.5rem gutter
}
.oneColumn {
    width: 12.261904761vw;
    width: 20%;
}
.threeColumns {
    width: 37.678571428vw;
    width: 60%;
}
.fourColumns {
    width: 50.416666666vw;
    width: 60%;
}

.paragraphContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.horizSection {
    display: flex;
    gap: 0.476190476vw;
    gap: 1rem;
} */

/* .section {
    margin-top: 12rem;
    display: flex;
    flex-direction: column;
}
.section-gray {
    display: flex;
    flex-direction: column;
    margin-top: 6rem;
    background-color: #ececec;
    border-radius: 0.5rem;
    padding: 6rem 14.2857%;
}

.bigHeader {
    font-size: 12rem;
    color: #00D287;
    font-family: 'space mono';
    line-height: 1;
    font-weight: bold;
}

.flower-symbol {
    font-size: 8rem;
    color: #00D287;
    letter-spacing: 1rem;
    line-height: 1;
}
.hero-row-two {
    margin-top: 14vh;
}

.right-align-container {
    display: flex;
    justify-content: flex-end;
}

.green-text {
    color: #00AE70;
}

.half-width {
    width: 50%;
}

.quote-box {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
}

.push-right {
    margin-left: 24.94047619vw;
    padding-left: 1rem;
}

.caption {
    font-size: 0.75rem;
}

.third-width {
    width: 32.3%;
}

.right-p {
    margin-left: 60%;
    margin-top: 1rem;
}

.zine-img {
    width: 49.9%;
    margin-bottom: 1rem;
} */
